import React, { useEffect, useRef } from "react";
import ReactLanguageSelect from 'react-languages-select';
import '../styles/language.css';

const LanguageComponent = ({ language, setLanguage }) => {
  const userLanguageRef = useRef(null);

  useEffect(() => {
    if (userLanguageRef.current) {
      localStorage.setItem("website-language", language)
      userLanguageRef.current.updateSelected(language);
    }
  }, [language]);

  return (
    <>
      <ReactLanguageSelect
        languages={["en", "pt"]}
        placeholder="Select Language"
        showSelectedLabel={true} 
        showOptionLabel={true}
        defaultLanguage={language}
        onSelect={(e) => setLanguage(e)}
        ref={userLanguageRef}
      />
    </>
  );
};

export default LanguageComponent;
