import React from 'react';
import { Routes, Route } from 'react-router-dom';
import TempHome from '../pages/tempHome';
import HomePage from '../pages/home';

// import Route from './Route';


const RoutesApp = () => {

  return (
    <Routes>
      <Route path="/" element={<HomePage/>} />
      <Route path="/new" element={<TempHome/>} />
    </Routes>
  );
}

export default RoutesApp;