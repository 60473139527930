import React from "react";
import Home from "../components/Home";
import Layout from "../layouts/Layout";
import 'react-languages-select/css/react-languages-select.css';
import { useEffect, useState } from "react";
import LanguageComponent from "../components/Language";
import About from "../components/About";
import Tools from "../components/Tools";
import Contact from "../components/Contact";
import Articles from "../components/Articles";

function TempHome (){
  const [LANGUAGE, setLANGUAGE] = useState(localStorage.getItem("website-language") == null ? "pt" : localStorage.getItem("website-language"));


  useEffect(() => {

    if (localStorage.getItem("website-language") === null){


      const languageDetected = Intl.DateTimeFormat().resolvedOptions().locale

  
      if (languageDetected === "pt-BR"){
          localStorage.setItem("website-language", "pt")
          setLANGUAGE("pt");
      }else{
          localStorage.setItem("website-language", "en")
          setLANGUAGE("en")
      }
    }
   

  }, []);
  


  return (
    <>
    {/* <LanguageComponent language={LANGUAGE} setLanguage={setLANGUAGE}/> */}
    <iframe id="mentorgpt-chat-iframe" src="https://mentorgpt-iframe.mentorapp.com.br/8fc9800c-7988-4a84-a245-bfd7d885011d"></iframe>

    <Layout language={LANGUAGE}>     
        <Home language={LANGUAGE}/>
        <About language={LANGUAGE} />
        <Tools language={LANGUAGE}/>
        {/* <Articles language={LANGUAGE}/> */}
        <Contact language={LANGUAGE}/>
    </Layout>

    </>
  );
};

export default TempHome;
