import React, { useState } from 'react';

import { BrowserRouter } from 'react-router-dom';

//import GlobalStyle from './styles/GlobalStyle';
import './styles/globals.css';

// import AppProvider from './hooks';

import RoutesApp from './routes';

// import SearchContext from './context/searchContext';
function App() {

  return (
    // <SearchContext.Provider value={[loading, setLoading]}>
      <BrowserRouter>
          <RoutesApp />
      </BrowserRouter>
    // </SearchContext.Provider>
  );
}

export default App;