import { languages } from "react-languages-select/lib/languages";
import ASSETS from "../resources/assets";
import STRINGS from "../resources/strings";
import { customersSliderProps } from "../sliderProps";

const Tools = ({language}) => {
  return (
    <section id="tools">
      <div className="container">
        <div className="roww">
          {/* Main Title */}
          <div className="resumo_fn_main_title">
            <h3 className="subtitle">{STRINGS.tools[language]}</h3>
            <h3 className="title">{STRINGS.languages[language]} &amp; {STRINGS.tools[language]}</h3>
            <p className="desc">
              {STRINGS.toolsDesc[language]}
            </p>
          </div>
          
          {/* /Main Title */}
          {/* Partners */}
          <div className="resumo_fn_partners">
            <ul>
              <li title="Neo4J">
                <a>
                  <img src={ASSETS.neo4j} alt="Neo4J" title="Neo4J"/>
                </a>
              </li>
              <li title="AWS">
                <a>
                  <img src={ASSETS.aws} alt="AWS" title="AWS"/>
                </a>
              </li>
              <li  title="Amazon Alexa">
                <a>
                  <img src={ASSETS.amazonAlexa} alt="Amazon Alexa" title="Amazon Alexa"/>
                </a>
              </li>
              <li title="Docker">
                <a>
                  <img src={ASSETS.docker} alt="Docker" title="Docker"/>
                </a>
              </li>
              <li title="Flask">
                <a>
                  <img src={ASSETS.flask} alt="Flask" title="Flask"/>
                </a>
              </li>
              <li  title="Jupyter">
                <a>
                  <img src={ASSETS.jupyter} alt="Jupyter" title="Jupyter" style={{maxWidth: 180}}/>
                </a>
              </li>
              <li title="MongoDB">
                <a>
                <img src={ASSETS.mongo} alt="Mongo" title="MongoDB"/>
                </a>
              </li>
              <li title="MySQL">
                <a>
                <img src={ASSETS.mysql} alt="MySQL" title="MySQL"/>
                </a>
              </li>
              <li title="NGINX">
                <a>
                <img src={ASSETS.nginx} alt="NGINX" title="NGINX"/>
                </a>
              </li>
              <li title="Node JS">
                <a>
                <img src={ASSETS.node} alt="Node JS" title="Node JS"/>
                </a>
              </li>
              <li title="Python">
                <a>
                <img src={ASSETS.python} alt="Python" title="Python" style={{maxWidth: 100}}/>
                </a>
              </li>
              <li title="Haskell">
                <a>
                <img src={ASSETS.haskell} alt="Haskell" title="Haskell" style={{maxWidth: 180}}/>
                </a>
              </li>
              <li title="DynamoDB">
                <a>
                <img src={ASSETS.dynamoDB} alt="DynamoDB" title="DynamoDB" style={{maxWidth: 180}}/>
                </a>
              </li>
              <li title="GitHub-Actions">
                <a>
                <img src={ASSETS.githubActions} alt="GitHub-Actions" title="GitHub-Actions" style={{maxWidth: 250}}/>
                </a>
              </li>
              <li title="Linux">
                <a>
                <img src={ASSETS.linux} alt="Linux" title="Linux"/>
                </a>
              </li>
              <li title="Redis">
                <a>
                <img src={ASSETS.redis} alt="Redis" title="Redis" style={{maxWidth: 180}}/>
                </a>
              </li>
              <li title="Rust">
                <a>
                <img src={ASSETS.rust} alt="Rust" title="Rust" style={{maxWidth: 120}}/>
                </a>
              </li>
              <li title="Google Cloud">
                <a>
                <img src={ASSETS.googleCloud} alt="Google Cloud" title="Google Cloud" style={{maxWidth: 180}}/>
                </a>
              </li>
              <li title="Selenium">
                <a>
                <img src={ASSETS.selenium} alt="Selenium" title="Selenium" style={{maxWidth: 180}}/>
                </a>
              </li>
              <li title="WhatsApp Cloud API">
                <a>
                <img src={ASSETS.whatsappCloudAPI} alt="WhatsApp Cloud API" title="WhatsApp Cloud API" style={{maxWidth: 180}}/>
                </a>
              </li>
              
            </ul>
          </div>
          {/* /Partners */}
          {/* Testimonials */}
          {/* /Testimonials */}
        </div>
      </div>
    </section>
  );
};

export default Tools;
