const ASSETS = {
    signature: "https://emmanuel-oliveira-com-assets.s3-accelerate.amazonaws.com/signature.png",
    meBackground:"https://emmanuel-oliveira-com-assets.s3-accelerate.amazonaws.com/me-background.jpg",
    me:"https://emmanuel-oliveira-com-assets.s3-accelerate.amazonaws.com/me.jpg",
    cvPt: "https://emmanuel-oliveira-certificates.s3.amazonaws.com/CV.pdf",
    cvEn: "https://emmanuel-oliveira-certificates.s3.amazonaws.com/CV.pdf",
    neo4j: "https://emmanuel-oliveira-com-assets.s3-accelerate.amazonaws.com/Neo4j.png",
    aws: "https://emmanuel-oliveira-com-assets.s3-accelerate.amazonaws.com/AWS.png",
    amazonAlexa: "https://emmanuel-oliveira-com-assets.s3-accelerate.amazonaws.com/AmazonAlexa.png",
    docker: "https://emmanuel-oliveira-com-assets.s3-accelerate.amazonaws.com/Docker.png",
    flask: "https://emmanuel-oliveira-com-assets.s3-accelerate.amazonaws.com/Flask.png",
    jupyter: "https://emmanuel-oliveira-com-assets.s3-accelerate.amazonaws.com/Jupyter.png",
    mongo: "https://emmanuel-oliveira-com-assets.s3-accelerate.amazonaws.com/Mongo.png",
    mysql: "https://emmanuel-oliveira-com-assets.s3-accelerate.amazonaws.com/MySQL.png",
    nginx: "https://emmanuel-oliveira-com-assets.s3-accelerate.amazonaws.com/Nginx.png",
    node: "https://emmanuel-oliveira-com-assets.s3-accelerate.amazonaws.com/Node.png",
    python: "https://emmanuel-oliveira-com-assets.s3-accelerate.amazonaws.com/Python.png",
    dynamoDB: "https://emmanuel-oliveira-com-assets.s3-accelerate.amazonaws.com/DynamoDB.png",
    githubActions: "https://emmanuel-oliveira-com-assets.s3-accelerate.amazonaws.com/GitHub-Actions.png",
    linux: "https://emmanuel-oliveira-com-assets.s3-accelerate.amazonaws.com/Linux.png",
    redis: "https://emmanuel-oliveira-com-assets.s3-accelerate.amazonaws.com/Redis.png",
    haskell: "https://cdn.prod.website-files.com/6047a9e35e5dc54ac86ddd90/63064c5652d40eda2eb7a838_33ac2334.png",
    rust: "https://emmanuel-oliveira-com-assets.s3.amazonaws.com/Rust.png",
    googleCloud: "https://emmanuel-oliveira-com-assets.s3.amazonaws.com/google-cloud-logo-0.png",
    selenium: "https://emmanuel-oliveira-com-assets.s3.amazonaws.com/selenium.png",
    whatsappCloudAPI: "https://emmanuel-oliveira-com-assets.s3.amazonaws.com/wppCloudApi.png",
    deepLearingMiniCourse: "https://emmanuel-oliveira-certificates.s3.amazonaws.com/2021-Minicurso+Deep+Learning+-2021.pdf",
    bigDataAlgorithms:"https://emmanuel-oliveira-certificates.s3.amazonaws.com/2021-Minicurso+Algoritmos+e+Modelos+de+Programa%C3%A7%C3%A3o+para+Big+Data.pdf",
    introductionDeepLearning: "https://emmanuel-oliveira-certificates.s3.amazonaws.com/2021-Introdu%C3%A7%C3%A3o+ao+Deep+Learning.pdf",
    pythonParsl: "https://emmanuel-oliveira-certificates.s3.amazonaws.com/2021-Introdu%C3%A7%C3%A3o+a+Python-Parsl.pdf",
    introductionMachineLearing: "https://emmanuel-oliveira-certificates.s3.amazonaws.com/2020-Introdu%C3%A7%C3%A3o+ao+Machine+Learning.pdf",
    signalAnalisys: "https://emmanuel-oliveira-certificates.s3.amazonaws.com/2020-Processamento+digital+de+sinais+para+an%C3%A1lise+tempo-frequ%C3%AAncia.pdf",
}

export default ASSETS;