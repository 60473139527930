import { useState } from "react";
import STRINGS from "../resources/strings";
import emailjs from '@emailjs/browser';

import { validate } from 'react-email-validator';

const Contact = ({language}) => {

  const [form, setForm] = useState({ email: "", name: "", msg: "" });
  const [active, setActive] = useState(null);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const onChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const { email, name, msg } = form;
  const onSubmit = (e) => {
    try{
    e.preventDefault();
    if (validate(email) && name && msg) {
      emailjs.send(process.env.REACT_APP_EMAIL_SERVICE_ID, process.env.REACT_APP_EMAIL_TEMPLATE_ID, {email: email, name: name, msg:msg}, process.env.REACT_APP_PUBLIC_API_KEY)
      .then((result) => {
          setSuccess(true);
          
      }, (error) => {
          console.log(error.text);
          setError(true);
          
      });
      
      setTimeout(() => {
        setForm({ email: "", name: "", msg: "" });
        setSuccess(false);
        setError(false);
      }, 2000);
    } else {
      
      setError(true);
      setForm({ email: "", name: "", msg: "" });
      setTimeout(() => {
        setError(false);
      }, 2000);
    }
  }catch(e){
    console.log(e);
    setError(true);
    setTimeout(() => {
      setForm({ email: "", name: "", msg: "" });
      setError(false);
    }, 2000);
  }

  };
  
  return (
    <section id="contact">
      <div className="container">
        <div className="roww resumo_fn_contact">
          {/* Main Title */}
          <div className="resumo_fn_main_title">
            <h3 className="subtitle">{STRINGS.contact[language]}</h3>
            <h3 className="title">{STRINGS.getInTouch[language]}</h3>
            <p className="desc">
              {STRINGS.contactDesc[language]}
            </p>
          </div>
          {/* /Main Title */}
          {/* Contact Form */}
          <form className="contact_form" onSubmit={(e) => onSubmit(e)}>
            <div
              className="success"
              data-success="Your message has been received, we will contact you soon."
              style={{ display: success ? "block" : "none" }}
            >
              <span className="contact_success">
                {STRINGS.sendMessageSuccess[language]}
              </span>
            </div>
            <div
              className="empty_notice"
              style={{ display: error ? "block" : "none" }}
            >
              <span>{STRINGS.nullField[language]}</span>
            </div>
            {/* */}
            <div className="items_wrap">
              <div className="items">
                <div className="item half">
                  <div
                    className={`input_wrapper ${
                      active === "name" || name ? "active" : ""
                    }`}
                  >
                    <input
                      onFocus={() => setActive("name")}
                      onBlur={() => setActive(null)}
                      onChange={(e) => onChange(e)}
                      value={name}
                      name="name"
                      id="name"
                      type="text"
                    />
                    <span className="moving_placeholder">{STRINGS.name[language]} *</span>
                  </div>
                </div>
                <div className="item half">
                  <div
                    className={`input_wrapper ${
                      active === "email" || email ? "active" : ""
                    }`}
                  >
                    <input
                      onFocus={() => setActive("email")}
                      onBlur={() => setActive(null)}
                      onChange={(e) => onChange(e)}
                      value={email}
                      name="email"
                      id="email"
                      type="email"
                    />
                    <span className="moving_placeholder">E-mail *</span>
                  </div>
                </div>
                <div className="item">
                  <div
                    className={`input_wrapper ${
                      active === "message" || msg ? "active" : ""
                    }`}
                  >
                    <textarea
                      onFocus={() => setActive("message")}
                      onBlur={() => setActive(null)}
                      name="msg"
                      onChange={(e) => onChange(e)}
                      value={msg}
                      id="message"
                    />
                    <span className="moving_placeholder">{STRINGS.message[language]} *</span>
                  </div>
                </div>
                <div className="item">
                  {/* <a id="send_message" href="#">
                    Send Message
                  </a> */}
                  <input
                    className="a"
                    type="submit"
                    id="send_message"
                    value={STRINGS.sendMessage[language]}
                  />
                </div>
              </div>
            </div>
          </form>
          {/* /Contact Form */}
          {/* Contact Info */}
              {/* /Contact Info */}
        </div>
      </div>
    </section>
  );
};

export default Contact;
